<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Tierkommunikation und Traumabegleitung</h1>
      </app-headline-group>
      <b-container class="content">

        <b-row>
          <b-col><h2>Tierkommunikation</h2></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Als Tierkommunikation wird die telepathisch non-verbale Verbindung bezeichnet. Mittels dieser Verbindung gelingt die Übertragung von Gedanken, Gefühlen und Bildern, dank des sechsten Sinnes. In den Naturvölkern ist dieser Sinn noch sehr aktiv und wird im Alltag genutzt.

              <i>(Das ist keine Heilung und ersetzt keinen Tierarzt oder Tierheilpraktiker)</i>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wann kann eine Tierkommunikation helfen?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Sie stärkt den Austausch zwischen Menschen und Tier, es können Blockaden gelöst werden und du kannst manche Hintergründe von einem Missverständnis verstehen und es kann beseitigt werden.
              Sie kann eine heilende Wirkung haben, auf seelischer und auf körperlicher Ebene und sie verbindet Herzen.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wie läuft eine Tierkommunikation ab?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Es gibt 3 Arten wie ich mich mit deinem Tier verbinden kann. Mittels einer Mediation, mit einer Herz-zu-Herz Verbindung und der reinen Absicht.
            </p>
            <p>
              Ist die Verbindung da, stelle ich mich vor und sage von wem ich komme.
              Ich frage das Tier, ob es mir etwas erzählen möchte, stelle deine Fragen und frage nach Botschaften. Wenn es das Tier braucht bekommt es heilende Energie und wir lösen Blockaden. Ich arbeite auch mit ThetaHealing®. Während der Kommunikation notiere ich alles was ich empfange.  Bei einer Kommunikation begleiten mich Heilsteine, Öle, Karten und meine verbindende, heilende Energie. Im Anschluss bekommst du alle Notizen per Sprachnachricht oder als Notiz. Wenn du die Nachricht erhalten hast, lässt du sie ankommen und wir vereinbaren ein Telefongespräch für den Abschluss.
              Als Geschenk erhältst du das Gespräch noch einmal per Post, von mir per Hand aufgeschrieben.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Was brauche ich für die Tierkommunikation?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Du schickst mir ein bis 2 Fotos deines Tieres, ich benötige die Augen als Tor, den Namen, das Geburtsdatum des Tieres und deine Fragen.
              Ich schreibe dir eine Rechnung, du überweist den Betrag und ich kommuniziere nach Erhalt mit deinem Tier.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Was kostet eine Tierkommunikation?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Ein Gespräch mit 2 Fragen kostet 40 Euro inkl. MwSt., bis 6 Fragen 85 Euro inkl. MwSt.
              Buchst du ein Paket mit 3 Gesprächen, in dem von dir gewählten Zeitraum kostet dich das 205 Euro inkl. MwSt. du sparst 50 Euro.
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum.
              Ich gebe kein Heilversprechen ab.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h2>Seelenkommunikation</h2></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Bei der Seelenkommunikation geht es um die telepathische non verbale Verbindung mit einem Menschen. Mittels dieser Verbindung gelingt eine Übertragung von Worten, Gedanken, Gefühlen, Bildern und andere Sinneseindrücke.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wobei kann die Seelenkommunikation helfen?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Es gibt einige Themen, die besprochen werden können. Vielleicht spürst du, dass du gerade nicht im Gleichgewicht bist, die Kommunikation in der Familie unrund ist und ich euch bei den Verbindungen innerhalb der Familie helfen kann. Vielleicht fragst du dich wie geht mein Weg weiter, oder was für alte Themen trage ich mit mir herum. Wir schauen, was ruft dich aus deinem tiefsten Inneren. Das sind nur wenige Beispiele.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wie läuft eine Seelenkommunikation ab?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Ich verbinde mich mit dir mit der reinen Absicht, ich stelle mich vor und wir beginnen das Gespräch. Ich stelle deine Fragen und empfange die Botschaften. Wenn du es brauchst und wünschst sende ich dir heilende Lichtenergie. Mittels ThetaHealing® ist auch noch vieles mehr möglich. Wenn du magst lege ich noch Karten intuitiv zu Unterstützung (Bsp. Seelenorakel, Krafttiere...). Während der Kommunikation notiere ich alles was ich empfange.
            </p>
            <p>
              Im Anschluss bekommst du alle Notizen per Sprachnachricht oder als Notiz. Wenn du die Nachricht erhalten hast, lässt du sie ankommen und wir vereinbaren ein Telefongespräch für den Abschluss. Als Geschenk erhältst du das Gespräch noch einmal per Post, von mir per Hand aufgeschrieben.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Was brauche ich für die Seelenkommunikation?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Ich brauche einen Auftrag von dir, den bekomme ich per Mail, Anruf oder Nachricht (auch über soziale Netzwerke). Gern erkläre ich dir noch einmal alles.
            </p>
            <p>
              In einem Videogespräch, via Zoom oder anderes sehe ich dich und bekomme deinen Namen, Geburtsdatum und deine Fragen.
            </p>
            <p>
              Ich schreibe dir eine Rechnung, du überweist den Betrag und ich kommuniziere nach Erhalt mit dir oder deiner Familie.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Was kostet eine Seelenkommunikation?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Ein Gespräch mit 2 Fragen kostet 40 Euro inkl. MwSt., bis 6 Fragen 85 Euro inkl. MwSt.
              Buchst du ein Paket mit 3 Gesprächen, in dem von dir gewählten Zeitraum kostet dich das 205 Euro inkl. MwSt. du sparst 50 Euro.
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum.
              Ich gebe kein Heilversprechen ab.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h2>ThetaHealing®</h2></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              ThetaHealing® ist ein meditativer Vorgang, bei dem wir uns fokussieren. Wir erschaffen eine physische, psychische und spirituelle Verbindung zur 7ten Schöpferebene. Durch Meditation geht das Gehirn in den geistigen Theta-Zustand,in diesem Zustand kannst du alles erschaffen was du magst, Wünsche manifestieren, Blockaden lösen, negative Glaubenssätze auflösen.
            </p>
            <p>
              Familienkommunikation 250Euro inkl. MwSt.
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum.
              Ich gebe kein Heilversprechen ab.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wie kommst du an eine Theta Situng?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Du schreibst mich an, per Mail oder anderes. Wir sprechen genauer über ThetaHealing®, wenn du eine Sitzung möchtest löst du einen Auftrag aus, ich schreibe dir eine Rechnung, du überweist.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Wie läuft eine Theta Sitzung ab?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Wir verbinden uns via Zoom oder anderes, besprechen dein Anliegen, legen Ziele fest und gehen dann in die Meditation.<br>Eine Sitzung dauert zwischen 1h – 1,5h.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h3>Was kostet eine Sitzung?</h3></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Eine Sitzung kostet 85 Euro inkl. MwSt. ein Paket mit drei Sitzungen im Zeitraum deiner Wahl kostet 205 Euro inkl. MwSt., du sparst 50 Euro.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h2>Traumabegleitung</h2></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <p>
              Eine Traumabegleitung beinhaltet ein Spezialpaket aus allem. Außerdem begleitet mich dabei eine gute Freundin, Doreen Krug von Beweglichkeit zu zweit. Sie ist Physiotherapeutin und Pferdeostheopathin.
            </p>
            <p>
              Mit meinem Blick, dem Wissen und der Heilung für das Innen und mit ihrem Blick, ihrem Wissen und ihrer Heilung für das Außen schnüren wir für dich ein ganz einzigartiges Paket. In einem Gespräch mit dir klären wir, was du brauchst, um mit deinem Trauma zu lachen und in deine Freiheit zu gehen. Deine Liebe, Grenzen und deinen Körper zu spüren und wenn du dich spürst dich der Außenwelt neu zu öffnen, zu senden und zu empfangen.
            </p>
            <p>
              Dieses Paket ist ganz individuell und so auch die Preise. Wenn es dich ruft und du Lust hast das wir dich auf deiner Reise begleiten dürfen melde dich bei uns.
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum. Wir geben kein Heilversprechen ab.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
