<template>
  <b-card :title="name"
          :img-src="source"
          :img-alt="name"
          img-top
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          class="member-card"
  >
    <transition name="fade">
      <div class="member-text" v-if="hover && text">
        <p>
          {{ text }}
        </p>
        <span @click="details = true">Mehr erfahren</span>
      </div>
    </transition>
    <b-modal v-model="details"
             ok-only
             hide-header
             size="lg"
    >
      <h2 class="modal-title">{{ name }}</h2>
      <div v-html="text" />
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: 'member-card',
  data: () => ({
    hover: false,
    details: false
  }),
  props: {
    name: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    mouseOver () {
      this.hover = true
    },
    mouseLeave () {
      this.hover = false
    }
  }
}
</script>

<style scoped>
  .member-card {
    position: relative;
  }

  h2.modal-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h4.card-title {
    margin: 0;
  }

  .member-text {
    position: absolute;
    padding: 1rem;
    left: 0;
    top: 0;
    height: calc(100% - 66px);
    width: 100%;
    background-color: RGBA(0, 0, 0, 0.75);
    color: #FFF;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  .member-text p {
    padding: 0;
    margin: 0;
    height: 70%;
    overflow: hidden;
  }

  .member-text span {
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    color: #A7C282;
    cursor: pointer;
  }

  #app .modal-title {
    margin-bottom: 0.5rem;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
