<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>ThetaHealing</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row class="mb-3">
          <b-col>
            <p>
              ThetaHealing® existiert bereits seit die Menschheit da ist. Vianna Stibal hat diese Methode mit Wissenschaftlern perfektioniert.
            </p>
            <p>
              Mit dieser energetischen Methode werden negative Glaubenssätze gelöscht und positive integriert, die Seele kann Heilung erfahren. Auch körperliche Beschwerden können mit dieser wundervollen Methode Heilung erfahren.
            </p>
            <p>
              Mit einer Meditaion begeben wir uns auf die 7te Ebene der Existenz, der Schöpferebene. Auf dieser Ebene ist alles eins.
            </p>
            <p>
              Alles besteht aus Energie uns ist miteinander verbunden und alles ist möglich.
            </p>
            <p>
              Sind wir auf der 7ten Ebene befinden wir uns im Theta Zustand. Das ist der Zustand im REM-Schlaf, dieser wird auch bei der Hypnose genutzt.
            </p>
            <p>
              Wir sind mit unserem Unterbewusstsein ganz tief verbunden und sind im Dialog mit deinem Inneren Sein.
            </p>
            <p>
              Dieses Innere Sein darf Heilung erfahren und dich strahlen lassen. Du gehst in deine Freiheit.
            </p>
            <p>
              Bist du bereit Heilung über die 7te Ebene der Existenz zu erfahren, dann kontaktier mich für ein kostenfreies 20 minütigen Infogespräch.
            </p>
            <p>
              Ich freue mich sehr auf dich, von Herzen Deine Nicole
            </p>
            <p>
              Eine Sitzung dauert zwischen 1h – 1,5h.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
