import Vue from 'vue'
import App from './App.vue'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Navigation from './components/dump/Navigation.vue'
import HeadlineGroup from './components/dump/HeadlineGroup.vue'

Vue.config.productionTip = false
Vue.use(BootstrapVue)

Vue.component('app-navigation', Navigation)
Vue.component('app-headline-group', HeadlineGroup)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
