<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Tierkommunikation</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row class="mb-3">
          <b-col>
            <p>
              Manchmal suchen wir nach Antworten und glauben sie nicht bekommen zu können. Zum Beispiel, weil unsere Tiere eine andere Sprache sprechen.
            </p>
            <p>
              Ich gebe deinem Tier eine Stimme, ich übersetze für dich und dein Tier, damit eure Verbindung noch tiefer wird und ihr euch intensiver auf den Weg zu euch selbst begeben werdet.
            </p>
            <p>
              Dein Tier hat vor etwas angst, ist oft krank, zeigt Verhaltensauffälligkeiten oder anderes, oder du möchtest einfach nur hören was es dir zu sagen hat, dann melde dich bei mir. Ebenso kann ich deinem Tier Botschaften von dir vermitteln.
            </p>
            <p>
              Mittels Telepathie verbinde ich mich mit deinem Tier und spreche mit ihm. Ich empfange dabei Bilder, Worte, Emotionen und spüre Blockaden im eigenen Körper. Telepathie ist das älteste Kommunikationsmittel. Es ist eine energetische Form der Kommunikation, mit der selbst Tiere und Pflanzen mit ihrer Umwelt in Kontakt treten.
            </p>
            <p>
              Wenn du dich gerufen fühlst, kontaktier mich und wir vereinbaren ein 20 minütiges Infogespräch.
            </p>
            <p>
              Ich freue mich sehr auf dich und deinen Begleiter.
            </p>
            <p>
              Von Herzen deine Nicole
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum.
            </p>
            <p>
              Ich gebe kein Heilversprechen ab.
            </p>
            <p>
              Ganz lieb, von Herzen Deine Nicole und ihr Team
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
