<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Magischer Frauenheilkreis</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row class="mb-3">
          <b-col>
            <p>
              Frauenheilkreise sind so alt wie die Menschheit. Im Ursprung wurden Frauenheilkreise während der Menstruation der Frauen abgehalten.
            </p>
            <p>
              Zwischendurch fanden sich immer wieder Themen, in verschiedenen Generationen, zum Zusammentreffen der Frauen.
            </p>
            <p>
              Während einer intensiven Kakaozeremonie erschienen mir die Frauen meiner vergangenen leben. Ich war sehr tief mit ihnen verbunden. Eine Schamanin, eine Heilerin und eine weise Frau, außerdem schlossen sich meine Seelentiere diesen starken Frauen an. Wir saßen um ein Feuer und es floss aus uns Heilenergie, welche sich bündelte und aufstieg. Schließlich wurde sie zu einer riesigen Wolke und platzte, die Heilenergie erreichte nun das Kollektiv.
            </p>
            <p>
              Ein großes Thema ist gerade die Heilung des Frauenkollektives, zurück zu den alten Weißheiten und Heilung erfahren. Frauen dürfen sich in ihrem Frausein bestärken und in den Schoß von Mutter Erde zurückkehren. Zurück zur Verbundenheit und Liebe.
            </p>
            <p>
              Wenn wir mit Freude und Liebe zu unserer Weiblichkeit finden, finden wir auch wieder tiefer und bewusster zur Männlichkeit. Das heißt wir werden Männern bewusster begegnen und somit werden wir uns intensiver aufeinander einlassen können und die Grenzen werden weicher.
            </p>
            <p>
              Jeder Kreis wird von einem bestimmten Thema getragen werden. Wir begrüßen uns, stimmen uns auf den Kreis ein, es gibt einen Input zu dem Thema, welches dran ist. Schließlich gehen wir in eine Meditation um uns ganz tief mit dem Thema zu verbinden.
            </p>
            <p>
              Ich durfte selbst 3 Tage die Verbundenheit eines solchen Kreises spüren und erleben, es ist so unglaublich viel transformiert und geheilt in dieser Zeit.
            </p>
            <p>
            Für einen Kreis plane ich 1,5 - 2h ein, damit auch genügend Platz für einen Austausch ist.
            </p>
            <p>
              Begleiten werden uns der Kakaogeist, Heilsteine und Öle.
            </p>
            <p>
              Gern kannst du auch im Umkreis von 50 km um Pirna herum einen Kreis organisieren und ich komme zu euch, oder du möchtest einen Kreis 1:1 nur für dich, oder deine Freundin und du. Sprich mich gern an und wir finden eine Form, die zu dir passt.
            </p>
            <p>
              Lass uns gemeinsam Grenzen überschreiten, transformieren und heilen.
            </p>
            <p>
              Ich bin mit dir verbunden, Seelenschwester.
            </p>
            <p>
              In Liebe Nicole
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
