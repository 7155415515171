<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Seelenbotschaften</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row class="mb-3">
          <b-col>
            <p>
              Ganz tief in uns drin wohnt unsere Seele, sie trägt so viele Antworten an uns in sich.
            </p>
            <p>
              Was blockiert mich, wo geht meine Reise hin, was sind meine Begabungen und noch vieles mehr.
            </p>
            <p>
              Mittels Telepathie kann ich mich mit deinem Inneren verbinden und dir Antworten auf deine Fragen geben.
            </p>
            <p>
              Mit diesen Antworten kannst du mehr und mehr mit dir tief in Verbindung gehen und deine Mitte und Ruhe finden.
            </p>
            <p>
              Die Telepathie ist die älteste Kommunikationsform, auch Tiere und Pflanzen kommunizieren so mit ihrer Umwelt.
            </p>
            <p>
              Wenn du Klarheit suchst, begleite ich dich dabei, sie zu finden.
            </p>
            <p>
              Fühlst du dich gerufen, kontaktier mich und wir vereinbaren ein 20 minütiges kostenfreies Infogespräch.
            </p>
            <p>
              Alles Liebe von Herzen Deine Nicole
            </p>
            <p>
              Alles was wir besprechen unterliegt dem Datenschutz und bleibt in unserem geschützten Raum.
            </p>
            <p>
              Ich gebe kein Heilversprechen ab.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
