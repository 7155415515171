<template>
  <b-col sm="12" md="3" class="mb-4">
    <b-card :img-src="image"
            :img-alt="alt"
            img-top
            @mouseover="mouseOver"
            @mouseleave="mouseLeave"
            class="product-card"
            style="position: relative"
    >
      <transition name="fade">
        <div class="product-text" v-if="hover" :style="{ '--title-height': titleHeight }">
          <b-btn @click="details = true" variant="outline-primary">Mehr erfahren</b-btn>
        </div>
      </transition>
      <b-card-text ref="cardText">
        {{ label }}
      </b-card-text>
    </b-card>
    <b-modal v-model="details"
             ok-only
             hide-header
             size="lg"
             centered
    >
      <h2 class="modal-title">{{ label }}</h2>
      <span v-html="text"></span>
    </b-modal>
  </b-col>
</template>

<script>
export default {
  name: 'product',
  data: () => ({
    hover: false,
    details: false,
    titleHeight: '24px'
  }),
  props: {
    image: { required: true },
    alt: { required: true, type: String },
    label: { required: true, type: String },
    text: { required: false, type: String }
  },
  methods: {
    mouseOver () {
      this.hover = true
    },
    mouseLeave () {
      this.hover = false
    }
  },
  created () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.titleHeight = `${this.$refs.cardText.clientHeight}px`
      })
    })
  }
}
</script>

<style scoped lang="postcss">
  .product-text {
    position: absolute;
    padding: 1rem;
    left: 0;
    top: 0;
    height: calc(100% - 2.5rem - var(--title-height));
    width: 100%;
    background-color: RGBA(0, 0, 0, 0.75);
    color: #FFF;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  >>> .btn-outline-primary {
    color: #A7C282;
    border-color: #A7C282;

    position: absolute;
    left: calc(50% - 64px);
    top: calc(50% - 19px);
  }

  >>> .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #A7C282;
    border-color: #A7C282;
  }

  h2.modal-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
