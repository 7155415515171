<template>
  <div class="news-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Horseaccompany</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row class="mb-3">
          <b-col>
            <p>
              In einer 3 stündigen Begegnung und in Bewegung mit meinen magischen Seelenbegleitern gehst du den Weg zu dir selbst. Die Pferde zeigen dir deine Themen, das erleichtert den Einstieg und kürzt den Weg, weil es keiner großen Worte drumherum benötigt.
            </p>
            <p>
              Ich bin die Übersetzerin, was dir die Seelenbegleiter aufzeigen wollen. Gemeinsam begeben wir uns auf den Weg alte Muster aufzulösen und neue zu integrieren, dazu nutzen wir Tools, die sich stimmig anfühlen.
            </p>
            <p>
              Ich mag gar nicht mehr dazu schreiben, da es eine einzigartige Reise zu dir selbst ist. So einzigartig, wie du bist. Lass dich tragen, heilen und finde dein Strahlen. Gehe in deine wahre Größe. In Liebe zu dir und dem was du bereits alles erschaffen hast.
            </p>
            <p>
              Du hast den Weg hierhergefunden, das ist kein Zufall, den den gibt es nicht.
            </p>
            <p>
              Du bist bereit Grenzen zu überschreiten und von dir heraus dein Leuchten in die Welt zu bringen.
            </p>
            <p>
              Wir begleiten dich dabei, klar und direkt. Du bist bereit!
            </p>
            <p>
              Kontaktier mich für ein kostenfreies 20 minütiges Infogespräch.
            </p>
            <p>
              Wir freuen uns auf Sie.
            </p>
            <p>
              Ganz lieb, von Herzen Deine Nicole und ihr Team
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style scoped lang="postcss">
  .news-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home/pferde.jpg') no-repeat top;
    background-size: cover;
  }

  .news-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .news-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .news-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
