<template>
  <div class="imprint-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Impressum</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row>
          <b-col>
            <h2>Kontakt</h2>

            <p>
              Nicole Panek<br>
              Lindenallee 19<br>
              01796 Pirna<br>
              <br>
              Telefon: <a href="tel:017641081527">01764 / 1081527</a><br>
              E-Mail: <a href="mailto:DieJurtenSiedler@gmx.net">DieJurtenSiedler@gmx.net</a>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <h2>Datenschutzbeauftragte</h2>

            <p>Nicole Panek</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>Layout & Programmierung</h2>

            <p>
              Frank Jogeleit<br/>
              Lange Straße 11<br/>
              01796 Pirna<br/>
              E-Mail: frank.jogeleit[at]web.de
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <h2>© Copyright</h2>
            <p>Das Copyright liegt bei Die Jurten Siedler. Alle Rechte vorbehalten. Die Jurten Siedler
              stellt die in dieser Präsentation enthaltenen Informationen zur persönlichen Nutzung kostenlos zur
              Verfügung. Eine kommerzielle Nutzung der Inhalte ist nur mit ausdrücklicher Genehmigung erlaubt. Alle
              veröffentlichten Inhalte sind urheberrechtlich geschützt. Für Irrtümer, Schreibfehler oder fehlerhafte
              Links sowie für Schäden, die Ihnen durch die Benutzung dieser Internetangebote direkt oder indirekt
              entstehen könnten, wird keine Haftung übernommen.</p>

            <h2>Urheberrechte</h2>

            <p>© Copyright Die Jurten Siedler. Alle Rechte vorbehalten. Text, Bilder, Grafiken, Sound,
              Animationen und Videos sowie deren Anordnung auf der Website unterliegen dem Schutz des Urheberrechts und
              anderer Schutzgesetze. Der Inhalt dieser Website darf nicht zu kommerziellen Zwecken kopiert, verbreitet,
              verändert oder Dritten zugänglich gemacht werden. Wir weisen darauf hin, dass auf den Websites enthaltene
              Bilder teilweise dem Urheberrecht Dritter unterliegen. Die Benutzung des Wappens des Landkreises oder von
              Zeichen, die diesem zum Verwechseln ähnlich sind, ohne Gestattung der tierische-begleitung.de wird als
              Ordnungswidrigkeit nach § 8 Abs. 1, 2 LOWiG verfolgt. Distanzierung (Disclaimer):
            </p>
            <p> Das Landgericht Hamburg hat mit Urteil vom 12.05.1998 (Az.: 312 O 85/98) entschieden, dass man durch die
              Anbringung eines Links die Inhalte der verlinkten Seiten ggf. mit zu verantworten hat. Dies kann - so das
              Landgericht - nur dadurch verhindert werden, dass man sich ausdrücklich von diesen Inhalten distanziert:
            </p>
            <p> Auf unserer Seite befinden sich Links zu anderen Seiten. Für alle diese Links gilt: Wir haben keinen
              Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten. Wir distanzieren uns ausdrücklich von
              den Inhalten aller von uns gelinkten Seiten und machen uns deren Inhalte nicht zu eigen. Diese Erklärung
              gilt für alle auf unserer Page angebrachten Links.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'imprint'
}
</script>

<style scoped lang="postcss">
  .imprint-page header {
    height: 60vh;
    width: 100vw;
    min-height: 800px;

    background: url('../../assets/imprint.jpg') no-repeat top;
    background-size: cover;
  }

  .imprint-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .imprint-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .imprint-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
