<template>
  <div class="team-page">
    <header>
      <div>
        <app-navigation />
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Das Team</h1>
      </app-headline-group>
      <b-container class="content">
        <h2>Ich und meine Begleiter</h2>
        <b-card-group columns>
          <app-member-card v-for="entry in team"
                           :name="entry.name"
                           :source="entry.image"
                           :key="entry.name"
                           :text="entry.text">
          </app-member-card>
        </b-card-group>
      </b-container>
    </section>
  </div>
</template>

<script>
import MemberCard from './Team/MemberCard.vue'
import Amy from '@/assets/portrait/amy.jpg'
import Kodi from '@/assets/portrait/kody.jpg'
import Crazy from '@/assets/portrait/crazy.jpg'
import Raeuberheld from '@/assets/portrait/raeuberheld.jpg'
import Tabea from '@/assets/portrait/tabea.jpg'
import Pegasus from '@/assets/portrait/pegasus.jpg'
import Nicole from '@/assets/portrait/nicole.jpg'
import Betty from '@/assets/portrait/betty.jpg'

export default {
  name: 'team',
  components: {
    appMemberCard: MemberCard
  },
  data () {
    return {
      team: [
        { image: Nicole, name: 'Nicole', text: 'Mein Name ist Nicole Panek, ich bin im Mai 1979 geboren. Ich habe 3 Töchter (20 Jahre, 7 Jahre und 1 Jahr). Mit meinem Lebenspartner gestalte ich seit 13 Jahren mein Leben jeden Tag aufs Neue. Wir werden noch von 4 Schafen, 1 Kater, 3 Hunden und 3 Pferden begleitet. Ich habe erfolgreich die Ausbildungen zur Tierwirtin, zur Heilerziehungspflegerin und das Studium zur Sozialpädagogin absolviert. Momentan studiere ich tiergestützte Arbeit. Berufliche Erfahrungen habe ich in der Schule für Erziehungshilfe, einer Mutter Kind Wohneinrichtung und den ambulanten Hilfen zur Erziehung (Familienhilfe, Erziehungsbeistand) gesammelt. Außerdem konnte ich erfolgreich den Systemischen Berater abschließen.' },
        { image: Pegasus, name: 'Pegasus', text: 'Pegasus im März 2017 geboren, kam im Dezember zu uns geflogen. Er ist etwas ganz besonderes und zeichnet sich durch sein mutiges und neugieriges Wesen aus.' },
        { image: Betty, name: 'Betty', text: 'Betty unser Neuzugang, von der Insel Usedom. Langsam und entspannt darf sie von den großen Hunden lernen und gemeinsam mit uns wachsen. Wir werden in der Welpenschule mit ihr das 1mal 1 des Hundehörens lernen.' },
        { image: Kodi, name: 'Kodi', text: 'Kodi ist geboren am 20.02.2011. Er ist als letzter von insgesamt 11 Welpen auf die Welt gekommen. Er hat bereits als Besuchshund im Altersheim gearbeitet und mich bei Klienten begleitet. In der Hundeschule hat er den Welpen- und Junghundekurs absolviert. Auch haben wir uns gemeinsam Agility, Dummytraining und Mantrailing beschäftigt, in der Hundeschule.' },
        { image: Raeuberheld, name: 'Räuberheld', text: 'Räuberheld wurde am 20.04.2006 geboren. Er begleitet mich seit dem Jahr 2009. ' },
        { image: Crazy, name: 'Crazy', text: 'Crazy, kurz Resi genannt, wurde am 19.02.2011 geboren. Sie ist die Schwester von Kodi. Crazy hat selbst auch schon als Altersheimbesuchshund gearbeitet und mich bei Klienten begleitet.In der Hundeschule hat sie den Welpen- und Junghundekurs absolviert. Auch haben wir gemeinsam in der Hundeschule uns mit Agility, Dummytraining und Mantrailing beschäftigt, in der Hundeschule.' },
        { image: Tabea, name: 'Tabea', text: 'Tabea, geboren am 10.05.2010. Sie wurde von der Hand von uns aufgezogen, da ihre Mutter gestorben ist, als sie gerade 3 Wochen alt war.' }
      ],
      horses: [
        { image: Pegasus, name: 'Pegasus', text: 'Pegasus im März 2017 geboren, kam im Dezember zu uns geflogen. Er ist etwas ganz besonderes und zeichnet sich durch sein mutiges und neugieriges Wesen aus.' },
        { image: Raeuberheld, name: 'Räuberheld', text: 'Räuberheld wurde am 20.04.2006 geboren. Er begleitet mich seit dem Jahr 2009. ' },
        { image: Tabea, name: 'Tabea', text: 'Tabea, geboren am 10.05.2010. Sie wurde von der Hand von uns aufgezogen, da ihre Mutter gestorben ist, als sie gerade 3 Wochen alt war.' }
      ],
      persons: [
        { image: Nicole, name: 'Nicole', text: 'Mein Name ist Nicole Panek, ich bin im Mai 1979 geboren. Ich habe 3 Töchter (20 Jahre, 7 Jahre und 1 Jahr). Mit meinem Lebenspartner gestalte ich seit 13 Jahren mein Leben jeden Tag aufs Neue. Wir werden noch von 4 Schafen, 1 Kater, 3 Hunden und 3 Pferden begleitet. Ich habe erfolgreich die Ausbildungen zur Tierwirtin, zur Heilerziehungspflegerin und das Studium zur Sozialpädagogin absolviert. Momentan studiere ich tiergestützte Arbeit. Berufliche Erfahrungen habe ich in der Schule für Erziehungshilfe, einer Mutter Kind Wohneinrichtung und den ambulanten Hilfen zur Erziehung (Familienhilfe, Erziehungsbeistand) gesammelt. Außerdem konnte ich erfolgreich den Systemischen Berater abschließen.' }
      ],
      dogs: [
        { image: Amy, name: 'Amy', text: 'Amy, die gute Seele geboren am 27.05.2007 gegangen am 19.10.2019. Sie hat meine Idee von der Entstehung bis zur Eröffnung begleitet. Sie hat uns Crazy und Kodi geschenkt.' },
        { image: Kodi, name: 'Kodi', text: 'Kodi ist geboren am 20.02.2011. Er ist als letzter von insgesamt 11 Welpen auf die Welt gekommen. Er hat bereits als Besuchshund im Altersheim gearbeitet und mich bei Klienten begleitet. In der Hundeschule hat er den Welpen- und Junghundekurs absolviert. Auch haben wir uns gemeinsam Agility, Dummytraining und Mantrailing beschäftigt, in der Hundeschule.' },
        { image: Crazy, name: 'Crazy', text: 'Crazy, kurz Resi genannt, wurde am 19.02.2011 geboren. Sie ist die Schwester von Kodi. Crazy hat selbst auch schon als Altersheimbesuchshund gearbeitet und mich bei Klienten begleitet.In der Hundeschule hat sie den Welpen- und Junghundekurs absolviert. Auch haben wir gemeinsam in der Hundeschule uns mit Agility, Dummytraining und Mantrailing beschäftigt, in der Hundeschule.' },
        { image: Betty, name: 'Betty', text: 'Betty unser Neuzugang, von der Insel Usedom. Langsam und entspannt darf sie von den großen Hunden lernen und gemeinsam mit uns wachsen. Wir werden in der Welpenschule mit ihr das 1mal 1 des Hundehörens lernen.' }
      ]
    }
  }
}
</script>

<style scoped>
  .team-page header {
    height: 75vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/home.jpg') no-repeat top;
    background-size: cover;
  }

  .team-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .team-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }

  .content .row {
    margin-bottom: 1rem;
  }

  .about {
    color: #000;
    cursor: pointer;
  }

  .about:hover {
    color: #777;
  }
</style>
