import Vue from 'vue'
import Router from 'vue-router'
import Hello from '@/components/views/Hello.vue'
import Team from '@/components/views/Team.vue'
import Prices from '@/components/views/Prices.vue'
import News from '@/components/views/News.vue'
import Frauenheilkreis from '@/components/views/Frauenheilkreis.vue'
import Horseaccompany from '@/components/views/Horseaccompany.vue'
import Tierkommunikation from '@/components/views/Tierkommunikation.vue'
import Seelenbotschaften from '@/components/views/Seelenbotschaften.vue'
import ThetaHealing from '@/components/views/ThetaHealing.vue'
import Imprint from '@/components/views/Imprint.vue'
import Sponsor from '@/components/views/Sponsor.vue'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Hello
    }, {
      path: '/team',
      name: 'team',
      component: Team
    }, {
      path: '/preise',
      name: 'price',
      component: Prices
    }, {
      path: '/aktuelle-angebote',
      name: 'news',
      component: News
    }, {
      path: '/frauenheilkreis',
      name: 'frauenheilkreis',
      component: Frauenheilkreis
    }, {
      path: '/horseaccompany',
      name: 'horseaccompany',
      component: Horseaccompany
    }, {
      path: '/tierkommunikation',
      name: 'tierkommunikation',
      component: Tierkommunikation
    }, {
      path: '/seelenbotschaften',
      name: 'seelenbotschaften',
      component: Seelenbotschaften
    }, {
      path: '/theta-healing',
      name: 'theta-healing',
      component: ThetaHealing
    }, {
      path: '/sponsoren',
      name: 'sponsoren',
      component: Sponsor
    }, {
      path: '/impressum',
      name: 'impressum',
      component: Imprint
    }
  ]
})
