<template>
  <div class="start-page">
    <header>
      <app-navigation/>
      <app-headline-group>
        <h1>Nicole Panek<br/>Beratung und Betreuung mit tierischer Begleitung</h1>
      </app-headline-group>
    </header>

    <section>
      <b-container class="content">
        <b-row>
          <b-col>
            <h2>
              Herzlich Willkommen bei den Jurten Siedlern
            </h2>
            <p>
              In Pirna Graupa haben wir tolle Angebote für euch. Seit 2019 bin ich selbstständig, in dieser Zeit ist viel passiert und ich möchte mich gern festlegen. Meine Hauptangebote sind Tierkommunikation und Traumabegleitung.
            </p>
            <p>
              Mit meinem Team bieten wir zusätzlich Betreuungs- und Entlastungsangebote nach §45b, als Pflegeleistung an. Ferienbetreuung in einem kleinen Rahmen und Honorartätigkeiten im pädagogischen Bereich.
            </p>

            <div class="carousel-border">
              <b-carousel
                id="carousel-1"
                fade
                indicators
                :interval="2000"
              >
                <b-carousel-slide :img-src="require('@/assets/home/gino.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/kind.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/cody.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/hunde.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/schafe.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/pferd.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/kuppel.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/home/pferde.jpg')" />
              </b-carousel>
            </div>

            <h2>Wir haben Angebote für</h2>

            <b-row>
              <product v-for="(product, index) in products"
                       :image="product.image"
                       :alt="product.alt"
                       :label="product.label"
                       :text="product.text"
                       :key="index"
              />
            </b-row>

            <h2 style="margin-top: 1rem">Kontakt</h2>

            <p>
              Bei Interesse an einem oder mehreren Angeboten, schreiben Sie mir eine Mail (<a href="mailto:DieJurtenSiedler@gmx.net">DieJurtenSiedler@gmx.net</a>) oder rufen an (<a href="tel:017641081527">01764 / 1081527</a>). Wir vereinbaren einen Termin und besprechen alles weitere.
            </p>

            <h3 style="margin-top: 1rem">Öffnungszeiten</h3>

            <p>
              Wir sind von Montag bis Freitag, in der Zeit von 8 bis 18 Uhr da.
            </p>

            <h2>Aufenthaltsort/Gelände</h2>

            <p>
              Wir befinden uns in Pirna Graupa, mitten im Grünen. Hier steht das Herzstück, unsere Jurte, mit Ofen, Kuschelecke, Essbereich und Aufenthaltsbereich.
              Weiterhin nutzen wir einen Bauwagen, als Multifunktionswagen, in ihm stehen die Schlafliegen, die Massageliege und die Beratungsecke. Hier können sich die Kinder auch gern zurückziehen.
              Als Klo dient ein Kompostklo, diese Ìdee kommt aus Schweden, wo die Klos in Kindereinrichtungen genutzt werden, um den Kreislauf der Natur den Kindern näher zu bringen.
              Unser Spül-, Waschbecken steht an unserer Geschichtenwand. Das Wasser bringen wir täglich frisch mit, da es keinen fließend Wasseranschluss gibt.
              Für eventuelle Einheiten mit Hund oder Pferd haben wir einen kleinen abgetrennten Bereich. Sonst zieren noch verschiedene Naturprojekte unser Gelände.
            </p>

            <div class="carousel-border">
              <b-carousel
                id="carousel-2"
                fade
                indicators
                :interval="4000"
              >
                <b-carousel-slide :img-src="require('@/assets/footer/malerei.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/footer/eule.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/footer/becken.jpg')" />
                <b-carousel-slide :img-src="require('@/assets/footer/zelt.jpg')" />
              </b-carousel>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Product from '@/components/dump/Product'
export default {
  name: 'hello',
  components: { Product },
  data: () => ({
    products: [
      {
        image: require('@/assets/angebot/mother.jpg'),
        alt: 'Mutter mit Kind',
        label: 'Eltern mit Kind/ern ab 1 Jahr',
        text: `- Kinder mit und ohne Handicap, ab 1 Jahr<br />
          - Bewegungsgruppe<br />
          - §45b Familienentlastender Dienst (für Kinder ab Pflegestufe 1)<br />
          - Beratungen zu Entwicklung, Beziehung, Bindung<br />
        `
      },
      {
        image: require('@/assets/angebot/children.jpg'),
        alt: 'Kindergarten',
        label: 'Einrichtungen',
        text: `- Unterricht mit Hund
          - GTA rund um das Tier und den bewussten Umgang<br />
          - Sozialpädagogische Leistungen auf Honorarbasis<br />
        `
      },
      {
        image: require('@/assets/angebot/book.jpg'),
        alt: 'Ratsuchende',
        label: 'Ratsuchende',
        text: `- Elternberatung<br />
          - Beziehungsberatung<br />
          - Persönlichkeitsberatung<br />
          - Beratung/Begleitung in Krisensituationen<br />
        `
      },
      {
        image: require('@/assets/angebot/dog.jpg'),
        alt: 'Hundebsitzer mit Hund',
        label: 'Tierbesitzer',
        text: `<b>Raindrop Technique für Ponies und Hunde</b><br />
          - mit Ölen spezielle Massagetechnik<br />
          - Blockaden lösen<br />
          - Entspannung fördern<br />
          - Immunsystem stärken<br />
          - Bindung festigen<br />
`
      }
    ]
  })
}
</script>

<style scoped lang="postcss">
  .start-page header {
    height: 100vh;
    width: 100vw;

    background-image: url('../../assets/team_01.jpg');
    background-size: cover;
    position: relative;
  }

  .start-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .headline-group {
    position: absolute;
    bottom: 3rem;
    left: 0;
  }

  >>> .carousel-border {
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  }
</style>
