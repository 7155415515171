<template>
  <div class="sponsor-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Sponsoren und Partner</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row>
          <b-col>
            <h2>Sponsoren</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item>
                <a href="https://www.opel-olbrich-grossschoenau.de" target="_blank">Autohaus Olbrich in Großschönau</a>
              </b-list-group-item>
              <b-list-group-item>
                <a href="https://mann2.de" target="_blank">MANN&sup2;</a>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>Partner</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item>
                <a href="https://www.mariafotoristika.de" target="_blank">Fotografin Maria Rafelt</a>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'sponsors'
}
</script>

<style scoped lang="postcss">
  .sponsor-page header {
    height: 60vh;
    width: 100vw;
    min-height: 800px;

    background: url('../../assets/partner.jpg') no-repeat top;
    background-size: cover;
  }

  .sponsor-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .sponsor-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .sponsor-page section a {
    color: #63300f;
  }

  .sponsor-page section a:hover {
    text-decoration: none;
    color: #2b1304;
  }

  .sponsor-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
