<template>
  <div class="prices-page">
    <header>
      <div>
        <app-navigation/>
      </div>
    </header>
    <section>
      <app-headline-group>
        <h1>Zusätzliche Angebote</h1>
      </app-headline-group>
      <b-container class="content">
        <b-row>
          <b-col><h2>Camp</h2></b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <p>
              Hallo ihr Natur- und Tierbegeisterten,<br />
              <br />
              ich habe ein wundervolles Angebot bekommen, die Wirkungsstätte für ein wundervolles Camp zu sein. In den Sommerferien vom 22.08.2021 – 28.08.2021 findet das Camp Pferdeflüstern und Hundefreundschaft – Tiersprache verstehen statt. Dabei geht es um das Leben mit der Natur und mit den Tieren, was brauchen sie, wie können wir auf sie achten und verstehen. Wenn du magst können auch kleine Aufgaben mit den Tieren erlernt werden. Also, lasst uns in eine neues Abenteuer starten, neue Freunde kennenlernen, Spaß haben und etwas Freiheitsluft schnuppern.
            </p>

            <p>
              Weitere Informationen dazu findet ihr auf <a href="https://camps.wwf-junior.de/camp/am-rande-des-nationalparks-sachsische-schweiz-pirna-ot-graupa-bei-dresden-sachsen/pferdeflustern-und-hundefreundschaft-tiersprache-verstehen-195" target="_blank">https://camps.wwf-junior.de</a>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h2>Niederschwelliges Betreuungs- und Entlastungsangebot</h2></b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <p>
              Das Angebot ist eine Pflegeleistung, enthalten in den Pflegegraden. Das Angebot gilt für zu Pflegende, aber auch für Angehörige. Beispielsweise können Kinder von Eltern mit Pflegestufe ebenso betreut werden, oder Eltern beraten, von Kindern mit Pflegestufe. Das Angebot rechnen wir direkt über die Krankenkasse ab.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col><h2>Ferienbetreuung</h2></b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <p>
              Ab und an werden wir eine paar <u>besondere Ferienangebote</u> anbieten. Diese könnt ihr euch auf Facebook oder Instagram erlesen. Natürlich auch gern per Telefon erfragen.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'prices'
}
</script>

<style scoped lang="postcss">
  .prices-page header {
    height: 60vh;
    width: 100vw;
    min-height: 700px;

    background: url('../../assets/angebote.jpg') no-repeat top;
    background-size: cover;
  }

  .prices-page header > div {
    background-color: RGBA(243, 235, 221, 0.6);
    border-bottom: 1px solid RGBA(255, 255, 255, 0.6);
  }

  .prices-page section {
    position: relative;
    background-color: #f3ebdd;
    min-height: 40vh;
    padding: 3rem 0;
  }

  .prices-page h2:first-of-type {
    margin-top: 0;
  }

  .headline-group {
    position: absolute;
    top: -101px;
    left: 0;
  }
</style>
